body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}
a.blue {
  color: #005aad;
  font-weight: 700;
}
textarea:disabled::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fff;
}
textarea:disabled:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
}
textarea:disabled::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
}
textarea:disabled:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fff;
}

.font-normal {
  font-weight: 400;
  font-size: 0.9rem;
}

.font-medium {
  font-weight: 500;
}

.green {
  color: #42aa06;
}
.blue {
  color: #005aad;
}
.orange {
  color: #fd6200;
}
.red {
  color: #f44436;
}

.display-inline-block {
  display: inline-block;
}
